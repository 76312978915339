import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Menu from "./components/Menu";
import Tiles from "./components/Tiles";
import Footer from "./Footer/footer";
import Contact from "./components/Contact";
import About from "./components/About";
import Tools from "./components/Tools";
import MNT from "./components/MNT";
import Inschrijven from "./components/device_wissel/Inschrijven";
import { useAuth } from "./components/AuthContext";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Tiles />} />
        <Route
          path="/account"
          element={isAuthenticated ? <About /> : <Navigate to="/" />}
        />
        <Route
          path="/help"
          element={isAuthenticated ? <Contact /> : <Navigate to="/" />}
        />
        <Route
          path="/tools"
          element={isAuthenticated ? <Tools /> : <Navigate to="/" />}
        />
        <Route
          path="/mnt"
          element={isAuthenticated ? <MNT /> : <Navigate to="/" />}
        />
        <Route
          path="/inschrijven"
          element={isAuthenticated ? <Inschrijven /> : <Navigate to="/" />} // Deze route toont de externe app in een iframe
        />
      </Routes>
      {/* <Footer /> */}
    </>
  );
};

export default App;
