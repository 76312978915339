import React from "react";
import "./home.css";

const Contact = () => {
  return (
    <>
      <hr className="border"></hr>
      <div className="content">
        <h2 className="pagename">Help</h2>
        <div className="sub-content">
          <div className="text-container">
            <p>
              Contactgegevens:<br></br>
              E-mailadres: wst@zuidzorg.nl<br></br>
              Telefoonnummer: 040 - 230 8400 (ma t/m vrij tussen 8:00 en 13:00
              uur bereikbaar)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
