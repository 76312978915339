import React, { useState, useEffect } from "react";

const Clock = () => {
  const [ctime, setTime] = useState(new Date().toLocaleTimeString());

  const updateTime = () => {
    const newTime = new Date().toLocaleTimeString();
    setTime(newTime);
  };

  useEffect(() => {
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <div>{ctime}</div>;
};

export default Clock;
