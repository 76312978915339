import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  const login = (user) => {
    setAuthenticated(true);
    setUserData(user);
    setlogouttimer();
  };

  const logout = () => {
    setAuthenticated(false);
    setUserData(null);
    // Optionally, you can clear user data from local storage here
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userdetails");
  };

  const setlogouttimer = () => {
    setInterval(logout, 14400000);
    // console.log("timer set");
  };

  const updateUserData = (newUserData) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newUserData,
    }));
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userData, login, logout, updateUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
