import {
  gmail_tile,
  drive_tile,
  calendar_tile,
  groups_tile,
  csearch_tile,
  remote_tile,
} from "../Apps/ImageImport";

export const googleApps = [
  {
    name: "Gmail",
    url: "https://mail.google.com",
    image: gmail_tile,
    condition: (userData) => userData.isMailboxSetup,
  },
  {
    name: "Drive",
    url: "https://drive.google.com",
    image: drive_tile,
    condition: (userData) => userData.isMailboxSetup,
  },
  {
    name: "Agenda",
    url: "https://calendar.google.com",
    image: calendar_tile,
    condition: (userData) => userData.isMailboxSetup,
  },
  {
    name: "Groepen",
    url: "https://groups.google.com",
    image: groups_tile,
    condition: (userData) => userData.isMailboxSetup,
  },
  {
    name: "Cloud Search",
    url: "https://cloudsearch.google.com/",
    image: csearch_tile,
    condition: (userData) => userData.isMailboxSetup,
  },
  {
    name: "Chrome Remote",
    url: "https://remotedesktop.google.com/support",
    image: remote_tile,
    condition: (userData) => userData,
  },
];
