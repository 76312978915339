import React from "react";
import { useAuth } from "./AuthContext";
import "./home.css";

const About = () => {
  const { isAuthenticated, userData } = useAuth();

  var werktelefoon = "";
  for (var j in userData.phones) {
    if (userData.phones[j].type === "work")
      werktelefoon = userData.phones[j].value;
  }

  var salarisnummer = "";
  if (userData.externalIds && userData.externalIds.length > 0) {
    salarisnummer = userData.externalIds[0].value;
  }

  var functie = "";
  if (userData.organizations && userData.organizations.length > 0) {
    functie = userData.organizations[0].title;
  }

  var manager = "";
  if (userData.relations && userData.relations.length > 0) {
    manager = userData.relations[0].value;
  }

  var afdeling = "";
  if (userData.organizations && userData.organizations.length > 0) {
    afdeling = userData.organizations[0].department;
  }

var bedrijfonderdeel = "";
var zuidzorg_ou = userData.orgUnitPath;

if (zuidzorg_ou.startsWith("/Wijkzorg")) {
  bedrijfonderdeel = "Wijkzorg";
} else if (zuidzorg_ou.startsWith("/TC")) {
  bedrijfonderdeel = "Thuiscoaching";
} else if (zuidzorg_ou.startsWith("/ZSC")) {
  bedrijfonderdeel = "Stichting";
} else if (zuidzorg_ou.startsWith("/Vastgoed")) {
  bedrijfonderdeel = "Vastgoed";
} else {
  bedrijfonderdeel = "Zuidzorg";
}

  return (
    <>
      <hr className="border"></hr>
      <div className="content">
        <h2 className="pagename">Mijn ZuidZorg Account</h2>
        <div className="sub-content">
          {isAuthenticated ? (
            <>
              <div className="text-container" style={{ lineHeight: "1.7" }}>
                <div>Naam: {userData.name.fullName}</div>
                <div>Email: {userData.primaryEmail}</div>
                <div>Werktelefoon: {werktelefoon}</div>
                <div>Salarisnummer: {salarisnummer}</div>
                <div>Functie: {functie}</div>
                <div>Afdeling: {afdeling}</div>
                <div>Manager: {manager}</div>
                <div>ZuidZorg: {bedrijfonderdeel}</div>
                <div>
                  Google MFA:
                  <span
                    style={{
                      color: userData.isEnrolledIn2Sv ? "#69be28" : "crimson",
                      fontWeight: "normal",
                    }}
                  >
                    {userData.isEnrolledIn2Sv ? " Aan" : " Uit"}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <p>User is not authenticated</p>
          )}
        </div>
      </div>
    </>
  );
};

export default About;
