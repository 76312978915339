import React from "react";
import "./home.css";

const MNT = () => {
  const links = [
    { url: "https://azorg.mijnio.nl/b/dashboard", label: "AZorg Dashboard" },
    { url: "https://sintanna.startmetons.nl", label: "Sint Anna" },
    { url: "https://archipelthuis.startmetons.nl/", label: "Archipel Thuis" },
    { url: "https://aygan.startmetons.nl/", label: "Aygan" },
    { url: "https://buurtzorg.ecare.nl/", label: "Buurtzorg" },
    { url: "https://breederzorg.startmetons.nl/", label: "Breederzorg" },
    { url: "https://joriszorg.startmetons.nl/", label: "Joris Zorg" },
    {
      url: "https://begeleidwonennederland.startmetons.nl/",
      label: "Begeleid Wonen Nederland",
    },
    {
      url: "https://vitalis.startmetons.nl/?jump=https%3A%2F%2Fvitalis.ioservice.net",
      label: "Vitalis",
    },
  ];

  return (
    <>
      <hr className="border" />
      <div className="content">
        <h2 className="pagename">MNT</h2>
        <ul className="links-list">
          {links.map((link, index) => (
            <li key={index}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="link-button"
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MNT;
