import {
  carefriend_tile,
  drive_tile,
  venvn_tile,
  stube_tile,
  afas_tile,
  vilans_tile,
} from "../Apps/ImageImport";

export const tcApps = [
  {
    name: "CareFriend",
    url: "https://zuidzorg.carefriend.nl/login",
    image: carefriend_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
  {
    name: "Vilans Protocollen",
    url: "https://www.vilanskickprotocollen.nl",
    image: vilans_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
  {
    name: "ZieZo Thuiscoaching",
    url: "https://drive.google.com/drive/folders/0ALG7rNov3r6AUk9PVA",
    image: drive_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
  {
    name: "V&VN",
    url: "https://mijn.venvn.nl/SignIn?returnUrl=/",
    image: venvn_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
  {
    name: "Leerhuis ZuidZorg",
    url: "https://zuidzorg.studytube.nl/discover",
    image: stube_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
  {
    name: "AFAS Insite",
    url: "https://login.afasonline.com",
    image: afas_tile,
    condition: (userOu, all_thuiscoaching_access) =>
      userOu.includes(all_thuiscoaching_access),
  },
];
