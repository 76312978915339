import React, { useState, useEffect } from "react";
import "./footer.css";

function Footer() {
  const texts = [
    "Blijf gezond met Zuidzorg!",
    "Samen zorgen we voor een betere toekomst!",
    "Dit is een <a href='https://www.zuidzorg.nl' target='_blank'>link naar Zuidzorg</a>!",
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible(false);

      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setVisible(true);
      }, 1000);
    }, 10000);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <footer>
      <div className={`footer-container ${visible ? "fade-in" : "fade-out"}`}>
        <div dangerouslySetInnerHTML={{ __html: texts[currentTextIndex] }} />
      </div>
    </footer>
  );
}

export default Footer;
