import {
  puur_tile,
  afas_tile,
  spendcloud_tile,
  ziezo_tile,
  venvn_tile,
  esculine_tile,
  stube_tile,
  vilans_tile,
  hoogland_tile,
  bosman_tile,
} from "../Apps/ImageImport";

const excludedWijkzorgDepts = process.env.REACT_APP_ZZP_LOSSE_DIEMSTEN;

export const wijkzorgApps = [
  {
    name: "PUUR Zorgdossier",
    url: "https://zuidzorg-zorgdossier.ecare.nl",
    image: puur_tile,
    condition: (userOu, all_wijkzorg_access) =>
      userOu.includes(all_wijkzorg_access),
  },
  {
    name: "PUUR Administratie",
    url: "https://zuidzorg.ecare.nl",
    image: puur_tile,
    condition: (userOu, all_wijkzorg_access) =>
      userOu.includes(all_wijkzorg_access),
  },
  {
    name: "Vilans Protocollen",
    url: "https://www.vilanskickprotocollen.nl",
    image: vilans_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "Hoogland Medical",
    url: "https://mijn.hooglandmedical.nl",
    image: hoogland_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "ZieZo",
    url: "https://ziezo.zuidzorg.nl",
    image: ziezo_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "Bosman",
    url: "https://zorgportaal.bosman.com/Paginas/Login.aspx?ReturnUrl=%2f",
    image: bosman_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "V&VN",
    url: "https://mijn.venvn.nl/SignIn?returnUrl=/",
    image: venvn_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "EscuLine",
    url: "https://platform.esculine.nl/zuidzorg",
    image: esculine_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "Leerhuis ZuidZorg",
    url: "https://zuidzorg.studytube.nl/discover",
    image: stube_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "Spendcloud",
    url: "https://zuidzorg.spend.cloud/",
    image: spendcloud_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
  {
    name: "AFAS Insite",
    url: "https://login.afasonline.com",
    image: afas_tile,
    condition: (userOu, all_wijkzorg_access, department) =>
      userOu.includes(all_wijkzorg_access) &&
      !excludedWijkzorgDepts.includes(department),
  },
];
