import {
  puur_tile,
  afas_tile,
  aws_tile,
  vgdb_tile,
  omnibox_tile,
  spendcloud_tile,
} from "../Apps/ImageImport";

export const vastgoedApps = [
  {
    name: "VGDB",
    url: "https://mijn.vgdb.nl/",
    image: vgdb_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
  {
    name: "Omniboxx",
    url: "https://zuidzorgvastgoed.omniboxx.nl/",
    image: omnibox_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
  {
    name: "PUUR Administratie",
    url: "https://zuidzorg.ecare.nl",
    image: puur_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
  {
    name: "AppStream",
    url: "https://accounts.google.com/o/saml2/initsso?idpid=C01posjoi&spid=322081757974&forceauthn=false",
    image: aws_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
  {
    name: "Spendcloud",
    url: "https://zuidzorg.spend.cloud/",
    image: spendcloud_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
  {
    name: "AFAS Insite",
    url: "https://login.afasonline.com",
    image: afas_tile,
    condition: (userOu, all_vastgoed_access) =>
      userOu.includes(all_vastgoed_access),
  },
];
